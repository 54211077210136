import {
  Badge,
  Box,
  Button,
  Input,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { DealResources } from 'services/resources/deal-flow/deal';
import { IDealResource } from 'services/resources/deal-flow/deal/types.d';

export const ReinvestmentTable = ({
  config: { saleType, tableType, generalFormState, setFieldValue },
}) => {
  const { investorRows } = generalFormState.context;

  const [selectedDeals, setSelectedDeals] = useState({});
  const [dealsFromDb, setDeals] = useState<IDealResource[]>([]);
  const [percentages, setPercentages] = useState<
    Record<string, Record<string, number>>
  >({});
  const [reinvestmentPercentages, setReinvestmentPercentages] = useState<
    Record<string, number>
  >({});

  useEffect(() => {
    async function fetchDealData() {
      const response = await DealResources.findAll();
      const data = response?.data?.results;
      setDeals(data || []);
    }
    fetchDealData();
  }, []);

  // Define updatePayload before using it
  const updatePayload = (
    currentSelectedDeals,
    currentPercentages,
    currentReinvestmentPercentages,
  ) => {
    // Build the payload
    const rawPayload = investorRows.map((investor) => {
      // 1) Overall reinvestment percentage
      const investorReinvestmentPercentage =
        currentReinvestmentPercentages[investor.id] || 0;

      // 2) The total reinvestment amount = montoVenta * overallReinvestment%
      const totalReinvestmentAmount =
        (Number(investor.montoVenta) * investorReinvestmentPercentage) / 100;

      const selectedDealsForInvestor = currentSelectedDeals[investor.id] || [];
      const percentagesForInvestor = currentPercentages[investor.id] || {};

      // 3) Build deals array
      const deals = selectedDealsForInvestor.map((dealId) => {
        const deal = dealsFromDb.find((d) => d.id === Number(dealId));

        // The percentage for this specific deal
        const dealPercentage = percentagesForInvestor[dealId] || 0;

        // The reinvestment amount for this specific deal
        // e.g.  totalReinvestmentAmount * (dealPercentage / 100)
        const dealReinvestmentAmount =
          (totalReinvestmentAmount * dealPercentage) / 100;

        return {
          deal_id: dealId,
          deal_name: deal?.slug || `Deal ${dealId}`,
          percentage: dealPercentage,
          reinvestmentAmount: dealReinvestmentAmount,
        };
      });

      return {
        investor_id: investor.id,
        usuario: investor.usuario,
        montoVenta: investor.montoVenta,
        reinvestment_percentage: investorReinvestmentPercentage,
        deals,
      };
    });

    // Optionally filter out any investors who are NOT reinvesting
    // (i.e. 0% overall or no deals, or no non-zero percentages).
    const filteredPayload = rawPayload.filter((item) => {
      // Condition #1: item.reinvestment_percentage > 0
      // Condition #2: at least one deal with a >0% or >0 reinvestmentAmount
      const hasNonZeroDeal = item.deals.some((d) => d.percentage > 0);
      return item.reinvestment_percentage > 0 && hasNonZeroDeal;
    });

    // Finally set to Formik (or state)
    setFieldValue('investorReinvestments', filteredPayload);
  };

  const handleDealSelection = (investorId, dealIds) => {
    const updatedSelectedDeals = {
      ...selectedDeals,
      [investorId]: dealIds,
    };
    setSelectedDeals(updatedSelectedDeals);
    updatePayload(updatedSelectedDeals, percentages, reinvestmentPercentages);
    setFieldValue('investorReinvestments', updatedSelectedDeals);
  };

  const handlePercentageChange = (investorId, dealId, value) => {
    const newPercentages = {
      ...percentages,
      [investorId]: {
        ...percentages[investorId],
        [dealId]: parseFloat(value) || 0, // Ensure it's a number
      },
    };
    setPercentages(newPercentages);
    setFieldValue('dealReinvestmentsPercentages', newPercentages);
    updatePayload(selectedDeals, newPercentages, reinvestmentPercentages);
  };

  const handleReinvestmentPercentageChange = (investorId, value) => {
    const newReinvestmentPercentages = {
      ...reinvestmentPercentages,
      [investorId]: parseFloat(value) || 0, // Ensure it's a number
    };
    setReinvestmentPercentages(newReinvestmentPercentages);
    setFieldValue('reinvestmentPercentages', newReinvestmentPercentages);
    updatePayload(selectedDeals, percentages, newReinvestmentPercentages);
  };

  const calculateTotalPercentage = (investorId) => {
    const investorPercentages = percentages[investorId] || {};
    return Object.values(investorPercentages).reduce(
      (total: number, percent) => total + (percent as number),
      0,
    );
  };

  // Calculate the total reinvestment amount for an investor
  const calculateReinvestmentAmount = (investor) => {
    const reinvestmentPercentage = reinvestmentPercentages[investor.id] || 0; // Use dynamic percentage
    const totalSaleAmount = investor.montoVenta || 0;
    return (totalSaleAmount * reinvestmentPercentage) / 100;
  };

  // Calculate the reinvestment amount for a specific deal
  const calculateDealReinvestmentAmount = (investorId, dealId) => {
    const reinvestmentAmount = calculateReinvestmentAmount(
      investorRows.find((i) => i.id === investorId),
    );
    const dealPercentage = percentages[investorId]?.[dealId] || 0;
    return (reinvestmentAmount * dealPercentage) / 100;
  };

  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>Email</Th>
            <Th>Porcentaje a reinvertir</Th>
            <Th>Seleccionar Deals</Th>
            <Th>Deals seleccionados</Th>
            <Th>Porcentaje por deal</Th>
            <Th>Monto reinversion</Th>
            <Th>Monto total de reinversion</Th>
            <Th>Monto total de venta</Th>
          </Tr>
        </Thead>
        <Tbody>
          {investorRows.map((investor) => {
            const totalPercentage = calculateTotalPercentage(investor.id);
            const isPercentageValid = totalPercentage <= 100;
            const reinvestmentAmount = calculateReinvestmentAmount(investor);

            return (
              <Tr key={investor.id}>
                <Td>{investor.usuario}</Td>
                <Td>
                  <div
                    style={{ position: 'relative', display: 'inline-block' }}
                  >
                    <Input
                      type="number"
                      value={reinvestmentPercentages[investor.id] || ''}
                      min={0}
                      max={100}
                      onChange={(e) =>
                        handleReinvestmentPercentageChange(
                          investor.id,
                          e.target.value,
                        )
                      }
                      style={{ paddingRight: '25px' }}
                    />
                    <span
                      style={{
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                      }}
                    >
                      %
                    </span>
                  </div>
                </Td>
                <Td>
                  <Menu closeOnSelect={false}>
                    <MenuButton
                      as={Button}
                      colorScheme="teal"
                      variant="outline"
                      style={{ minWidth: '200px', textAlign: 'left' }}
                    >
                      {selectedDeals[investor.id]?.length > 0
                        ? `${selectedDeals[investor.id].length} deals selected`
                        : 'Select deals'}
                    </MenuButton>
                    <MenuList maxHeight="200px" overflowY="auto">
                      <MenuOptionGroup
                        type="checkbox"
                        value={selectedDeals[investor.id] || []}
                        onChange={(selectedOptions: string[] | string) =>
                          handleDealSelection(investor.id, selectedOptions)
                        }
                      >
                        {dealsFromDb.map((deal) => (
                          <MenuItemOption key={deal.id} value={String(deal.id)}>
                            {deal.slug} - {deal.raised}
                          </MenuItemOption>
                        ))}
                      </MenuOptionGroup>
                    </MenuList>
                  </Menu>
                </Td>
                <Td>
                  {selectedDeals[investor.id]?.length > 0 ? (
                    <List spacing={2} maxHeight="150px" overflowY="auto">
                      {selectedDeals[investor.id].map((dealId) => {
                        const deal = dealsFromDb.find(
                          (d) => d.id === Number(dealId),
                        );
                        return (
                          <ListItem key={dealId}>
                            <Badge colorScheme="blue" mr={2}>
                              {deal?.slug}
                            </Badge>
                            <Box as="span" fontSize="sm">
                              {deal?.raised}
                            </Box>
                          </ListItem>
                        );
                      })}
                    </List>
                  ) : (
                    <Box fontSize="sm" color="gray.500">
                      No deals selected
                    </Box>
                  )}
                </Td>
                <Td>
                  {selectedDeals[investor.id]?.length > 0 ? (
                    <Stack spacing={2}>
                      {selectedDeals[investor.id].map((dealId) => {
                        const deal = dealsFromDb.find((d) => d.id === dealId);
                        return (
                          <Box key={dealId}>
                            <Text fontSize="sm" fontWeight="bold">
                              {deal?.slug}
                            </Text>
                            <Input
                              type="number"
                              placeholder="Percentage"
                              value={percentages[investor.id]?.[dealId] || ''}
                              onChange={(e) =>
                                handlePercentageChange(
                                  investor.id,
                                  dealId,
                                  e.target.value,
                                )
                              }
                              size="sm"
                              width="100px"
                            />
                          </Box>
                        );
                      })}
                      {!isPercentageValid && (
                        <Text fontSize="sm" color="red.500">
                          Total percentage exceeds 100%
                        </Text>
                      )}
                    </Stack>
                  ) : (
                    <Box fontSize="sm" color="gray.500">
                      No deals selected
                    </Box>
                  )}
                </Td>
                <Td>
                  {selectedDeals[investor.id]?.length > 0 ? (
                    <List spacing={2}>
                      {selectedDeals[investor.id].map((dealId) => {
                        const dealReinvestmentAmount =
                          calculateDealReinvestmentAmount(investor.id, dealId);
                        return (
                          <ListItem key={dealId}>
                            <Text fontSize="sm">
                              {dealReinvestmentAmount.toLocaleString('es-MX', {
                                style: 'currency',
                                currency: 'MXN',
                                maximumFractionDigits: 2,
                              })}
                            </Text>
                          </ListItem>
                        );
                      })}
                    </List>
                  ) : (
                    <Box fontSize="sm" color="gray.500">
                      No deals selected
                    </Box>
                  )}
                </Td>
                <Td>
                  {reinvestmentPercentages[investor.id] != null
                    ? (
                        (reinvestmentPercentages[investor.id] *
                          investor.montoVenta) /
                        100
                      ).toLocaleString('es-MX', {
                        style: 'currency',
                        currency: 'MXN',
                        maximumFractionDigits: 2,
                      })
                    : ''}
                </Td>
                <Td>
                  {Number(investor.montoVenta).toLocaleString('es-MX', {
                    style: 'currency',
                    currency: 'MXN',
                    maximumFractionDigits: 2,
                  })}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
