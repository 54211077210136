import {
  Button,
  Checkbox,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { Loading } from 'components/elements';
import { useFetchInvestments } from 'components/hooks';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

// Hook personalizado
const useRowUpdater = (rows, setRows, setFieldValue, values, combinedExit) => {
  const calculateMontoVenta = (row, parcialPercentage) => {
    // Determinar baseValue a partir de sales_value siempre
    const baseValue = values.sales_value;

    // if (combinedExit) {
    //   if (row.saleType === 'buyback') {
    //     // si rebuy_percentage es un porcentaje del valor total
    //     baseValue = values.sales_value * (values.rebuy_percentage / 100);
    //   } else if (row.saleType === 'sm') {
    //     // si sm_percentage es un porcentaje del valor total
    //     baseValue = values.sales_value * (values.sm_percentage / 100);
    //   }
    // }

    const totalMontoVenta =
      (parseFloat(row.prorrata) / 100) * baseValue * (parcialPercentage / 100);

    return (totalMontoVenta * (Number(values.sales_percentage) / 100)).toFixed(
      2,
    );
  };

  const updateRow = (index, updatedValues) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      const row = { ...updatedRows[index], ...updatedValues };

      if (
        updatedValues.saleType ||
        updatedValues.parcialPercentage !== undefined
      ) {
        const parcialPercentage =
          updatedValues.parcialPercentage !== undefined
            ? updatedValues.parcialPercentage
            : row.parcialPercentage;

        row.montoVenta = calculateMontoVenta(row, parcialPercentage);
      }

      updatedRows[index] = row;
      setFieldValue('investorRows', updatedRows); // Actualiza Formik
      return updatedRows;
    });
  };

  return { updateRow };
};

interface FormValues {
  id: number;
  sales_value: number;
  rebuy_percentage?: number;
  sm_percentage?: number;
  sales_percentage: number;
}

export const SaleTable = ({
  combinedExit = false,
  saleValueSM = 0,
  saleValueRebuy = 0,
  config: { saleType, tableType },
}) => {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const [rows, setRows] = useState<
    {
      id: number;
      investmentId: number;
      usuario: string;
      prorrata: string;
      parcialPercentage: number;
      montoVenta: number | string;
      saleType: string;
      agreement_date: string;
    }[]
  >([
    {
      id: 0,
      investmentId: 0,
      usuario: '',
      prorrata: '',
      parcialPercentage: 0,
      montoVenta: 0,
      saleType: '',
      agreement_date: '',
    },
  ]);

  const { updateRow } = useRowUpdater(
    rows,
    setRows,
    setFieldValue,
    values,
    combinedExit,
  );

  // Function to calculate `montoVenta` based on the type and percentages
  const calculateMontoVenta = (
    prorrata: string,
    salesValue: number,
    percentage: number,
  ) => {
    const result =
      (parseFloat(prorrata) * salesValue * (percentage / 100)) / 100;
    return result;
  };

  const removeRow = (index) => {
    setRows(rows.filter((_, i) => i !== index));
  };

  const { investmentData, isLoading } = useFetchInvestments(
    values.id,
    values.sales_value,
  );

  console.log('Investment data', investmentData);

  // Initialize rows when data is loaded
  useEffect(() => {
    let parcialPercentage = 100;
    if (tableType === 'parcial') {
      parcialPercentage = 0;
    }

    const initialRows = investmentData.map((registro) => ({
      id: registro.id,
      investmentId:
        typeof registro.investmentId === 'number' ? registro.investmentId : 0,
      usuario:
        typeof registro.usuario === 'object' && 'email' in registro.usuario
          ? registro.usuario.email ?? ''
          : '',
      prorrata: Number(registro.prorrata).toFixed(2),
      parcialPercentage,
      montoVenta:
        tableType === 'total'
          ? (
              Number(registro.montoVenta ?? 0) *
              (Number(values.sales_percentage) / 100)
            ).toFixed(2)
          : '0',
      saleType,
      agreement_date: registro.agreement_date ?? '',
    }));

    setRows(initialRows);
    setFieldValue('investorRows', initialRows);
  }, [
    investmentData,
    tableType,
    saleType,
    values.rebuy_percentage,
    values.sm_percentage,
    values.sales_value,
  ]);

  // Handle percentage change
  // const handlePercentageChange = (index, value) => {
  //   const newPercentage = Math.min(Math.max(value, 0), 100); // Ensure between 0 and 100
  //   setRows((prevRows) => {
  //     const updatedRows = [...prevRows];
  //     const row = updatedRows[index];
  //     row.parcialPercentage = newPercentage;
  //     row.montoVenta =
  //       (parseFloat(row.prorrata) *
  //         values.sales_value *
  //         (newPercentage / 100)) /
  //       100;

  //     console.log('Updated rows', updatedRows);
  //     setFieldValue('investorRows', updatedRows);
  //     return updatedRows;
  //   });
  // };

  // Elimina una fila
  const handleRemoveRow = (index) => {
    setRows((prevRows) => {
      const updatedRows = prevRows.filter((_, i) => i !== index);
      setFieldValue('investorRows', updatedRows); // Actualiza Formik
      return updatedRows;
    });
  };

  if (isLoading) {
    return <Loading isLoading={isLoading} />;
  }

  return (
    <TableContainer
      width="100%"
      minWidth="800px" // Ensure a minimum width for better readability
      overflowX="auto"
    >
      <Table>
        <Thead>
          <Tr>
            <Th>Usuario</Th>
            <Th>Prorrata</Th>
            <Th>Porcentaje a vender</Th>
            <Th>Monto de Venta</Th>
          </Tr>
        </Thead>
        <Tbody>
          {rows.map((row, index) => (
            <Tr key={row.id}>
              <Td>{row.usuario}</Td>
              <Td>{`${Number(row.prorrata).toFixed(2)} %`}</Td>
              <Td>
                <div style={{ position: 'relative', display: 'inline-block' }}>
                  <Input
                    type="number"
                    value={row.parcialPercentage}
                    min={0}
                    max={100}
                    onChange={(e) =>
                      updateRow(index, {
                        parcialPercentage: Math.max(
                          0,
                          Math.min(100, Number(e.target.value)),
                        ),
                      })
                    }
                    style={{ paddingRight: '25px' }} // Add some padding to the right to make space for the % sign
                  />
                  <span
                    style={{
                      position: 'absolute',
                      right: '10px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }}
                  >
                    %
                  </span>
                </div>
              </Td>
              <Td>{`$${Number(row.montoVenta)
                .toFixed(2)
                .toLocaleString()}`}</Td>
              <Td>
                <Button
                  colorScheme="red"
                  size="sm"
                  onClick={() =>
                    setRows((prevRows) =>
                      prevRows.filter((_, i) => i !== index),
                    )
                  }
                >
                  Eliminar
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
