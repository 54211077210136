import { IDealPayload } from 'services/resources/deal-flow/deal/types.d';
import { assign, createMachine, StateMachine } from 'xstate';

import { DealMachineEvent } from '../events';
import { DealSaleMachineNodes } from '../nodes';
import { DealSaleMachineSchema } from '../schemas';

const CONTEXT = {
  investorRows: [
    {
      id: 0,
      investmentId: 0,
      usuario: '',
      prorrata: 0,
      parcialPercentage: 0,
      montoVenta: 0,
      saleType: '',
      agreement_date: '',
    },
  ],
};

export const getDealSaleMachine = (
  initial: string | symbol = DealSaleMachineNodes.INVESTORS,
): StateMachine<IDealPayload, DealSaleMachineSchema, DealMachineEvent> =>
  createMachine({
    id: 'dealSale',
    context: CONTEXT as IDealPayload,
    initial,
    states: {
      [DealSaleMachineNodes.INVESTORS]: {
        on: {
          NEXT: {
            target: DealSaleMachineNodes.INVESTMENTS,
            actions: assign({
              investorRows: (context, event) =>
                // Guardamos la información proveniente del primer formulario
                event.data as typeof CONTEXT.investorRows,
            }),
          },
        },
      },
      [DealSaleMachineNodes.INVESTMENTS]: {
        type: 'final',
      },
    },
  });
