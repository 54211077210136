import { Box, FormLabel, Select } from '@chakra-ui/react';
import { MoneyField, PercentageField } from 'components/inputs';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

import { BuyersTable } from './BuyersTable';
import { SaleTable } from './SaleTable';

const SecondaryMarket = (props) => {
  const {
    saleAmountIntput = true,
    saleValueSM = 0,
    saleValueRebuy = 0,
  } = props;
  const { setFieldValue, values } = useFormikContext<any>();

  useEffect(() => {
    console.log('values in sm', values);
    setFieldValue('sale_type', 'sm'); // Set sale_type to "buyback" only on mount
  }, []);

  const [buyers, setBuyers] = useState([
    { user: null, percentage: 0, montoCompra: '' },
  ]);

  const parseCurrency = (value) => Number(value.replace(/[^0-9.-]+/g, ''));

  const changeHandler = (e) => {
    setFieldValue('sales_value', parseCurrency(e));
  };

  return (
    <Box width="100%">
      <Box>
        <FormLabel textAlign="left">Porcentaje de venta</FormLabel>
        <PercentageField
          name="sale_percentage"
          size="md"
          w={{ base: '100%', lg: '33%' }}
          onChange={(e) => {
            const value = (e.target as HTMLInputElement).value.replace('%', '');
            setFieldValue('sales_percentage', value);
          }}
        />
      </Box>
      {saleAmountIntput && (
        <Box>
          <FormLabel textAlign="left">Monto total de venta</FormLabel>
          <MoneyField
            mb={4}
            name="sales_value"
            size="md"
            w={{ base: '100%', lg: '66%' }}
            onChange={(e) =>
              changeHandler((e.target as HTMLInputElement).value)
            }
          />
        </Box>
      )}

      <Box>
        <FormLabel>Tipo de salida</FormLabel>
        <Select
          name="exit_type"
          placeholder="Tipo de salida"
          onChange={(e) => setFieldValue('exit_type', e.target.value)}
          disabled={!values.sales_value || Number(values.sales_value) <= 0}
        >
          <option value="total">Total</option>
          <option value="parcial">Parcial</option>
        </Select>
      </Box>

      {values.exit_type === 'total' && (
        <>
          <Box overflowX="auto" width="100%" mb={4}>
            <SaleTable
              saleValueSM={saleValueSM}
              saleValueRebuy={saleValueRebuy}
              config={{
                saleType: 'sm',
                tableType: 'total',
              }}
            />
          </Box>
          <BuyersTable
            buyers={buyers}
            setBuyers={setBuyers}
            salesValue={values.sales_value}
            setFieldValue={setFieldValue}
          />
        </>
      )}

      {values.exit_type === 'parcial' && (
        <>
          <Box overflowX="auto" width="100%" mb={4}>
            <SaleTable
              saleValueSM={saleValueSM}
              saleValueRebuy={saleValueRebuy}
              config={{
                saleType: 'sm',
                tableType: 'parcial',
              }}
            />
          </Box>
          <BuyersTable
            buyers={buyers}
            setBuyers={setBuyers}
            salesValue={values.sales_value}
            setFieldValue={setFieldValue}
          />
        </>
      )}
    </Box>
  );
};

export default React.memo(SecondaryMarket);
