import { Text } from '@chakra-ui/react';
import { useMachine } from '@xstate/react';
import {
  DealFormButtons,
  Divider,
  H1,
  PageHeadline,
  ProgressBullets,
} from 'components/elements';
import { FinancialForm, GeneralForm, SecurityForm } from 'components/forms';
import { InvestmentMemoForm } from 'components/forms/DealForms/InvestmentMemoForm';
import {
  addErrorToast,
  addSuccessToast,
} from 'containers/ToastManager/store/slice';
import { FormikProps } from 'formik';
import fp from 'lodash/fp';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { DealResources } from 'services/resources/deal-flow/deal';
import { IDealPayload } from 'services/resources/deal-flow/deal/types.d';
import { getDealMachine } from 'x-state/machines/DealMachine';
import { DealMachineNodes } from 'x-state/nodes';

import { Nodes, STAGE_TITLE } from './helpers';

export const DealUI = {
  [DealMachineNodes.GENERAL]: GeneralForm,
  [DealMachineNodes.FINANCIAL]: FinancialForm,
  [DealMachineNodes.SECURITY_TYPE]: SecurityForm,
  [DealMachineNodes.INVESTMENT_MEMO]: InvestmentMemoForm,
};

export const CreatePage: React.FC = (): JSX.Element => {
  const [state, send] = useMachine(getDealMachine());

  const { t } = useTranslation('deals');

  const dispatch = useDispatch();

  const history = useHistory();

  const ref = useRef<FormikProps<IDealPayload>>(null);

  const UI = DealUI[state?.value as string];

  const [{ loading }, handleOnSubmit] = useAsyncFn(
    async (v: IDealPayload) => {
      try {
        const { cover: c, ...values } = { ...state?.context, ...v };

        const p = new FormData();

        if (!fp.isNil(c)) p.append('cover', c as File, (c as File).name);

        fp.compose(
          fp.each((k: string) => p.append(k, values[k])),
          fp.keys,
          fp.omitBy(fp.isNil),
        )(values);

        if (state?.value === DealMachineNodes.GENERAL) {
          const response = await DealResources.create(p as IDealPayload);

          send('NEXT', { id: response?.data?.id, ...values });
        } else {
          const id = state?.context?.id as number;

          await DealResources.update(id, p as IDealPayload);

          if (state?.value !== DealMachineNodes.INVESTMENT_MEMO) {
            send('NEXT', { ...values });
          } else {
            history.push('/deals');
          }
        }

        dispatch(addSuccessToast('toast.success.network'));
      } catch (e) {
        dispatch(addErrorToast('toast.errors.unrestricted-file-upload'));
      }
    },
    [ref?.current, state?.value],
  );

  const prepareOnSubmit = useCallback(() => {
    if (ref?.current) ref?.current?.submitForm();
  }, [ref?.current]);

  return (
    <>
      <PageHeadline
        quote={t('page.create.quote')}
        title={t('page.create.title')}
      />

      <Divider />

      <ProgressBullets
        bullets={Object.keys(Nodes).length / 2}
        my={12}
        selected={Nodes[state.value as keyof Nodes] + 1}
      />

      <H1 fontSize="lg" fontWeight="black" mb={2} textAlign="center">
        {t(STAGE_TITLE[state?.value as string])}
      </H1>

      <Text
        align="center"
        color="blackAlpha.500"
        fontSize="sm"
        fontWeight="bold"
        mb={8}
      >
        {t('page.create.subtitle')}
      </Text>

      <UI
        mb={12}
        mx="auto"
        onSubmit={handleOnSubmit}
        px={8}
        ref={ref}
        w="512px"
      />

      <DealFormButtons
        isDisabled={loading}
        isLoading={loading}
        mb={12}
        mx="auto"
        onSubmit={prepareOnSubmit}
        px={8}
        w="700px"
      />
    </>
  );
};
