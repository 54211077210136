import { Box } from '@chakra-ui/react';
import {
  Form,
  Formik,
  FormikProps,
  FormikValues,
  useFormikContext,
} from 'formik';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IDealPayload } from 'services/resources/deal-flow/deal/types.d';
import { ICommonDealStateProps } from 'types.d';

import { ReinvestmentTable } from './components';
import { FinancialFormValidationSchema } from './helpers';

export const MarketFinancialForm = forwardRef<
  FormikProps<IDealPayload>,
  ICommonDealStateProps
>((props, ref): JSX.Element => {
  const { initialValues = {}, onSubmit, generalFormState, ...rest } = props;

  const { setFieldValue } = useFormikContext<FormikValues>();
  const { t } = useTranslation('deals');

  return (
    <Formik
      enableReinitialize
      initialValues={{ ...initialValues }}
      innerRef={ref}
      onSubmit={onSubmit}
      validationSchema={FinancialFormValidationSchema}
    >
      <Box as={Form} {...rest} width="100%">
        <ReinvestmentTable
          config={{
            saleType: 'sm',
            tableType: 'total',
            generalFormState,
            setFieldValue,
          }}
        />
      </Box>
    </Formik>
  );
});

MarketFinancialForm.displayName = 'MarketFinancialForm';
