import { Text } from '@chakra-ui/react';
import { useMachine } from '@xstate/react';
import {
  DealFormButtons,
  Divider,
  H1,
  PageHeadline,
  ProgressBullets,
} from 'components/elements';
import { MarketGeneralForm } from 'components/forms';
import { MarketFinancialForm } from 'components/forms/SecondMarketForms/MarketFinancialForm';
import {
  addErrorToast,
  addSuccessToast,
} from 'containers/ToastManager/store/slice';
import { Form, Formik, FormikProps } from 'formik';
import fp from 'lodash/fp';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { DealResources } from 'services/resources/deal-flow/deal';
import { IDealPayload } from 'services/resources/deal-flow/deal/types.d';
import { getDealSaleMachine } from 'x-state/machines/DealSaleMachine';
import { DealSaleMachineNodes } from 'x-state/nodes';

import { Nodes, STAGE_TITLE } from './helpers';

// import { Nodes, STAGE_TITLE } from './helpers';

export const DealSaleUI = {
  [DealSaleMachineNodes.INVESTORS]: MarketGeneralForm,
  [DealSaleMachineNodes.INVESTMENTS]: MarketFinancialForm,
};

export const CreatePage = () => {
  const [state, send] = useMachine(getDealSaleMachine());

  const history = useHistory();

  const { t } = useTranslation('deals');

  const dispatch = useDispatch();

  const ref = useRef<FormikProps<IDealPayload>>(null);

  const UI = DealSaleUI[state?.value as string];

  const [{ loading }, handleOnSubmit] = useAsyncFn(
    async (v: IDealPayload) => {
      try {
        const {
          cover: c,
          investorRows,
          buyersRows,
          investorReinvestments,
          ...values
        } = { ...state?.context, ...v };

        const p = new FormData();

        // Si hay un archivo adjunto, agréguelo
        if (!fp.isNil(c)) p.append('cover', c as File, (c as File).name);

        // Serializa investorRows y agrégalo a FormData
        if (Array.isArray(investorRows)) {
          p.append(
            'investorRows',
            JSON.stringify(
              investorRows.map((row) => ({
                id: row.id,
                investmentId: row.investmentId,
                usuario: row.usuario,
                prorrata: row.prorrata,
                parcialPercentage: row.parcialPercentage,
                montoVenta: row.montoVenta,
                saleType: row.saleType,
                agreement_date: row.agreement_date,
              })),
            ),
          );
        }

        if (Array.isArray(buyersRows)) {
          p.append(
            'buyersRows',
            JSON.stringify(
              buyersRows.map((row) => ({
                id: row.id,
                user: row.user,
                percentage: row.percentage,
                montoCompra: row.montoCompra,
              })),
            ),
          );
        }

        if (investorReinvestments) {
          p.append(
            'investorReinvestments',
            JSON.stringify(investorReinvestments),
          );
        }

        // Añade el resto de los valores al FormData
        fp.compose(
          fp.each((k: string) => p.append(k, values[k])),
          fp.keys,
          fp.omitBy(fp.isNil),
        )(values);

        const id = values.id as number;

        // Realiza la petición para actualizar
        await DealResources.update(id, p as IDealPayload);

        // Notificación de éxito
        dispatch(addSuccessToast('toast.success.network'));
        send('NEXT', { data: investorRows });
        if (state?.value === DealSaleMachineNodes.INVESTMENTS) {
          // Redirige a la página de detalles
          history.push(`sale-metrics/${id}`);
        }
      } catch (e) {
        // Notificación de error
        dispatch(addErrorToast('toast.errors.network'));
      }
    },
    [ref?.current, state?.value],
  );

  return (
    <Formik
      initialValues={{
        sales_value: 0,
        sales_percentage: 0,
        exit_type: '',
        sale_type: '',
        id: 0,
        investorRows: [],
        buyersRows: [],
        investorReinvestments: {},
        investmentId: null,
        rebuy_percentage: 0,
        sm_percentage: 0,
      }}
      onSubmit={handleOnSubmit}
    >
      {({ submitForm }) => (
        <Form>
          <>
            <PageHeadline
              quote={t('page.sales-create.quote')}
              title={t('page.sales-create.title')}
            />

            <Divider height="50px" />

            <H1
              fontSize="lg"
              fontWeight="black"
              mb={2}
              mt={7}
              textAlign="center"
            >
              {t('page.sales-create.title')}
            </H1>

            <ProgressBullets
              bullets={Object.keys(Nodes).length / 2}
              my={12}
              selected={Nodes[state.value as keyof Nodes] + 1}
            />
            <H1 fontSize="lg" fontWeight="black" mb={2} textAlign="center">
              {t(STAGE_TITLE[state?.value as string])}
            </H1>
            <Text
              align="center"
              color="blackAlpha.500"
              fontSize="sm"
              fontWeight="bold"
              mb={8}
            >
              {t('page.create.subtitle')}
            </Text>

            <UI
              mb={12}
              mx="auto"
              onSubmit={handleOnSubmit}
              px={8}
              ref={ref}
              w="512px"
              generalFormState={state}
              setFieldValue={ref.current?.setFieldValue}
            />

            <DealFormButtons
              isDisabled={loading}
              isLoading={loading}
              mb={12}
              mx="auto"
              onSubmit={submitForm}
              px={8}
              w="700px"
            />
          </>
        </Form>
      )}
    </Formik>
  );
};
