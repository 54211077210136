import { Flex, Icon, Tr } from '@chakra-ui/react';
import { useGenericContext } from 'components/contexts/useGenericContext';
import { H1, Modal } from 'components/elements';
import { CompanyForm } from 'components/forms';
import { CompanyReportForm } from 'components/forms/CompanyReportForm';
import { Td, TdActions, TdLogo } from 'components/lib';
import { CustomTable, FormDrawer } from 'components/modules';
import {
  addErrorToast,
  addSuccessToast,
} from 'containers/ToastManager/store/slice';
import { FormikHelpers, FormikProps } from 'formik';
import { getCompanyLogo, getId, getResults, isResultsEmpty } from 'helpers';
import fp from 'lodash/fp';
import { nanoid } from 'nanoid';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { RiFileEditFill } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { useToggle } from 'react-use';
import { CompanyResources } from 'services/resources/deal-flow/company';
import {
  ICompanyPayload,
  ICompanyResource,
} from 'services/resources/deal-flow/company/types.d';
import { CompanyReportResources } from 'services/resources/deal-flow/company-report';
import { ICompanyReportPayload } from 'services/resources/deal-flow/company-report/types.d';

import { getCreated, getDescription, getName, HEADERS } from '../helpers';

export const Page: React.FC = (): JSX.Element => {
  const {
    active,
    defaultRestore,
    doFetch,
    isDrawerOpen,
    onPrepareDelete,
    onPrepareDrawer,
    params,
    registry,
    setRegistry,
    state,
    toggleDrawer,
  } = useGenericContext<ICompanyResource>();

  const { t } = useTranslation('companies');

  const dispatch = useDispatch();

  const ref = useRef<FormikProps<ICompanyPayload>>(null);

  const [isOpen, toggle] = useToggle(false);

  const prepareFormValues = useCallback(
    () => ({
      ...registry,
      industries: fp.compose(
        fp.map(fp.toString),
        fp.get('industries'),
      )(registry),
      differentiations: fp.compose(
        fp.map(fp.toString),
        fp.get('differentiations'),
      )(registry),
    }),
    [registry],
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const defaultPatch = async ({
    decklink: d,
    logo: l,
    ...v
  }: ICompanyPayload) => {
    const p = new FormData();
    if (!fp.isString(l)) p.append('logo', l as File, (l as File).name);
    if (!fp.isString(d) && !fp.isNil(d))
      p.append('decklink', d as File, (d as File).name);

    fp.compose(
      fp.each((k: string) => p.append(k, v[k])),
      fp.keys,
      fp.omitBy(fp.isNil),
    )(v);

    if (fp.compose(fp.isNil, getId)(v)) {
      await CompanyResources.create(p as ICompanyPayload);
    } else {
      await CompanyResources.update(getId(v), p as ICompanyPayload);
    }

    await doFetch(params);
    toggleDrawer();
  };

  const prepareOnSubmit = useCallback(() => {
    if (ref?.current) ref?.current?.submitForm();
  }, [ref]);

  const prepareCreateReport = useCallback((company: ICompanyResource) => {
    setRegistry(company);
    toggle();
  }, []);

  const createReport = useCallback(
    async (
      { report: r, ...v }: ICompanyReportPayload,
      h?: FormikHelpers<ICompanyReportPayload>,
    ) => {
      try {
        const p = new FormData();

        if (!fp.isString(r)) p.append('report', r as File, (r as File).name);

        fp.compose(
          fp.each((k: string) => p.append(k, v[k])),
          fp.keys,
        )(v);

        await CompanyReportResources.create(p as ICompanyReportPayload);

        dispatch(addSuccessToast('toast.success.files'));
        toggle();
      } catch (e) {
        dispatch(addErrorToast('toast.errors.unrestricted-file-upload'));
      } finally {
        h?.setSubmitting(false);
      }
    },
    [],
  );

  return (
    <>
      <CustomTable
        isEmpty={isResultsEmpty(state)}
        headers={HEADERS}
        isLoading={state?.loading}
        mx={8}
      >
        {getResults(state)?.map((row: ICompanyResource) => (
          <Tr key={nanoid()} position="relative">
            <Td>{getId(row)}</Td>
            <TdLogo src={getCompanyLogo(row)}>{getName(row)}</TdLogo>
            <Td>{getDescription(row)}</Td>
            <Td>{getCreated(row)}</Td>
            <TdActions
              customButtonBg="orange"
              customButtonIcon={<Icon as={RiFileEditFill} color="white" />}
              isActive={active}
              onCustomCallback={() => prepareCreateReport(row)}
              onDelete={() => onPrepareDelete(row)}
              onRestore={() => defaultRestore(row, { is_active: true })}
              onUpdate={() => onPrepareDrawer(row)}
            />
          </Tr>
        ))}
      </CustomTable>

      <FormDrawer
        isOpen={isDrawerOpen}
        isDisabled={!!ref?.current?.isSubmitting || !!ref?.current?.isValid}
        isLoading={!!ref.current?.isSubmitting}
        onClose={toggleDrawer}
        onSubmit={prepareOnSubmit}
      >
        <CompanyForm
          initialValues={prepareFormValues()}
          onSubmit={defaultPatch}
          ref={ref}
        />
      </FormDrawer>

      <Modal isOpen={isOpen} onClose={toggle}>
        <Flex
          direction="column"
          h="100%"
          justify="center"
          maxW="512px"
          mx="auto"
        >
          <H1 mb={12}>{t('reports.modal.title')}</H1>
          <CompanyReportForm
            initialValues={{
              company: registry?.id,
              report: undefined,
              is_active: true,
            }}
            onSubmit={createReport}
          />
        </Flex>
      </Modal>
    </>
  );
};
